<template>
  <div>

    <validation-observer ref="simpleRulesEntry">
      <div
        v-for="(key,index) in dataItem"
        :key="index"
      >
        <b-card
          no-body
        >
          <b-card-header>
            <b-card-title>{{ (index + 1) }}.Kayıt</b-card-title>
            <b-button
              variant="outline-danger"
              class="btn-icon rounded-circle"
              @click="removeRow(index)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-card-header>
          <b-card-body>
            <quick-stock-line-form :item-key="index" />
          </b-card-body>
        </b-card>
      </div>
      <b-row>
        <b-col
          class="text-left"
        >
          <b-button
            variant="primary"
            class="text-left"
            :disabled="dataItem.submitStatus"
            @click="EntrySubmitData"
          >
            <feather-icon icon="SaveIcon" />

            Kaydet
          </b-button>
        </b-col>
        <b-col class="float-right">
          <b-button
            variant="success"
            class="text-left ml-2 float-right"
            @click="addRow()"
          >
            <feather-icon icon="PlusIcon" />
            Yeni Satır
          </b-button>
        </b-col>
      </b-row>

    </validation-observer>
  </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import QuickStockLineForm from '@/views/Admin/Stocks/Components/QuickStockOutLineForm.vue'

export default {
  name: 'QuickStocksAddLines',
  components: {
    QuickStockLineForm,
    BButton,
    ValidationObserver,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
  },
  data() {
    return {
      entry: null,
    }
  },
  computed: {
    stockData() {
      return this.$store.getters['stocks/dataItem']
    },
    dataItem() {
      return this.$store.getters['stockLines/dataItem']
    },
    saveData() {
      return this.$store.getters['stockLines/dataSaveStatus']
    },

  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.dataItem.submitStatus = false
        this.$store.commit('stockLines/RESET_DATA_ITEM_QUICK')
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.$store.commit('stockLines/RESET_DATA_ITEM_QUICK')
  },
  methods: {
    EntrySubmitData() {
      this.$refs.simpleRulesEntry.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.$store.dispatch('stockLines/batchInsert', this.dataItem)
        } else {
          this.showToast({
            variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: 'Tüm alanları doldurmalısınız.',
          })
        }
      })
    },
    addRow() {
      this.dataItem.push({
        id: null,
        entry_amount: null,
        output_amount: null,
        price: null,
        invoice_number: null,
        id_projects: null,
        id_warehouses: null,
        id_suppliers: null,
        id_stocks: null,
        id_users: null,
        submitData: false,
      })
    },
    removeRow(key) {
      if (this.dataItem.length > 1) {
        this.dataItem.splice(key, 1)
      } else {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: 'Daha fazla satır silemezsiniz.',
        })
      }
    },
    getStockData(stockId) {
      this.$store.dispatch('stocks/getDataItem', stockId)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
